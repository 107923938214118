<template>
    <div>
        <div class="popup__header">
            <div class="title">Передача проекта</div>
            <div class="text-color-white-trans text-bold mt4">&nbsp;</div>
        </div>


        <div class="popup__content">
            <div class="text-size-xs text-color-grey">КАК ОТДАВАТЬ ЗЕМСДИЗАЙН ЗАКАЗЧИКУ?</div>

            <div class="mt12 mb40">
                <Tumbler :options="giveOptions"
                    v-model="form.rate.value" 
                    @change="form.rate.error=false" 
                    :widthAuto="true"
                    class="--give-options"
                    :error="form.rate.error"></Tumbler>
            </div>
            <div class="df df-space-between mt40">
                <submit-btn @click="send" tag="button" :type="btnType">
                    Подтвердить
                </submit-btn>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import Tumbler from '@/components/Tumbler.vue';
    import SubmitBtn from '@/components/forms/SubmitBtn.vue';

    export default {
        name: "GiveProject",
        components: {
            Tumbler,
            SubmitBtn,
        },
        methods: {
            removeError(form) {
                form.error = false;
            },
            send(){
            },
        },
        data: function () {
            return {
                btnType: 'normal',
                form: {
                    rate: {
                        value: {name: 'ЧЕРЕЗ КУРАТОРА И АЛЕКСЕЯ', id: 1},
                        error: false,
                        repeatError: false,
                    },
                },
                giveOptions: [
                    {name: 'ЧЕРЕЗ КУРАТОРА И АЛЕКСЕЯ', id: 1},
                    {name: 'АВТОМАТИЧЕСКИ', id: 2},
                ],

            }
        },
        mounted() {

        },
        computed: {
            ...mapState({
                popups: state => state.popups.items,
            }),
        },
    }
</script>

<style scoped>

</style>
