<template>
    <div class="baza-item__header">
        <div class="l">
            <span class="name">{{ item.name }}</span>
        </div>
        <div class="r">
            <sms-out-of-time-link :item="item" v-if="hasState([ 'request_new_metering_empty']) && !isGiveDesign"/>
            <span class="txt" v-if="isGiveDesign"><span v-if="item.specification_id">Ожидает  {{ item.props.waitDesign }} дн.</span></span>
            <template v-else-if="isNPSCollect">
                <span class="txt">получили проект {{ item.props.giveDesignDiffDays }} дн. назад</span>
                <nps-collect-tooltip :item="item" v-if="item.props.nps_status === 'not-call'"/>
            </template>
            <nps-measure-tooltip  v-else-if="isNPSMeasure" :item="item" />
            <repair-terminate-tooltip :item="item"
                                      v-else-if="item.props.repairComment && hasState([ 'repair_terminate_*'])"/>
            <!-- Концепции - на паузе | Директор и владелец  -->
            <concepts-pause-tooltip :item="item"
                                    v-else-if="item.props.reasonPause && hasState([ 'concepts_paused_director', 'concepts_paused_TD'])"/>
            <!-- 3Д - на паузе | Директор и владелец  -->
            <d3-pause-tooltip :item="item"
                              v-else-if="item.props.reasonPause3D && hasState([ '3d_paused_director', '3d_paused_TD'])"/>
            <repair-prepay-tooltip :item="item"
                                   v-else-if="item.props.repairPrepayComment && hasState([ 'repair_prepay_M','repair_prepay_K','repair_prepay_R','repair_prepay_AR','repair_prepay_director','repair_prepay_TD']) && menuTopItemCode === 'repair'"/>
            <repair-comment-tooltip :item="item"
                                    v-else-if="(item.props.repairComment || item.props.acceptReportComment) && hasState(['repair_request_default','repair_request_director','repair_request_director','repair_request_analytic','repair_request_M','repair_think_default','repair_think_director','repair_think_director','repair_think_M','repair_reject_default','repair_reject_director','repair_reject_director','repair_reject_M','repair_aside_default','repair_aside_director','repair_aside_director','repair_aside_M','repair_in_process_default','repair_in_process_M','repair_in_process_director','repair_in_process_fin','repair_terminate_default','repair_terminate_director','repair_terminate_director','repair_terminate_M','repair_finish_default','repair_finish_director','repair_finish_director','repair_finish_M','repair_finish_analytic', 'repair_request_TD', 'repair_think_TD', 'repair_reject_TD', 'repair_aside_TD', 'repair_prepay_TD', 'repair_in_process_TD', 'repair_terminate_TD', 'repair_finish_TD', 'repair_request_AR', 'repair_think_AR', 'repair_reject_AR', 'repair_aside_AR', 'repair_prepay_AR', 'repair_in_process_AR', 'repair_terminate_AR', 'repair_finish_AR']) && menuTopItemCode === 'repair'"/>
            <request-think-tooltip :item="item" v-else-if="hasState([ 'request_think_*'])"/>
            <!-- Переданы без ремонта -->
            <drawing-transferred-tooltip :item="item"
                                         v-else-if="hasState(['drawing_transferred_default','drawing_transferred_director','drawing_transferred_TD','drawing_transferred_analytic']) && item.props.reasonTransferred"/>
            <request-reject-tooltip :item="item"
                                    v-else-if="(hasState([ 'request_rejected_default','request_rejected_director','request_rejected_analytic','request_rejected_TD']) && (item.extProps.stamp !== 'not_pay_on_time')) || (item.extProps.stamp === 'not_pay_on_time' && item.props.reasonReject) && menuTopItemCode !== 'repair'"/>

            <div class="" v-else-if="hasState(statesForDirectorMarks) && requestStatusDays > 24">
                <span class="i i-info3 mr4"
                      :class="{'i--yellow': requestStatusDays < 36,'i--red': requestStatusDays > 35}"></span><span
                class="txt" v-html="requestStatusTimePassed"></span>
            </div>
            <request-new-tooltip :item="item"
                                 v-else-if="item.props.extra_comment && hasState([ 'request_new_*', 'request_new_metering_empty'])"/>
            <repair-not-need-tooltip :item="item"
                                     v-else-if="item.props.autosale_refuse_comment && item.props.autosale_repair_reason === 'Ремонт не нужен' && hasState([ 'drawing_refuse_to_repair_*'])"/>
            <purpose-new-tooltip :item="item" v-else-if="hasState([ 'purpose_new_*'])"/>
            <purpose-not-need-tooltip :item="item"
                                      v-else-if="hasState([ 'purpose_repair_not_need_*','request_cant_call_default'])"/>
            <purpose-think-tooltip :item="item" v-else-if="hasState([ 'purpose_think_*'])"/>
            <purpose-set-tooltip :item="item" v-else-if="$route.params.status === 'purpose_set'"/>
            <span class="txt" v-else v-html="requestStatusTimePassed"></span>

            <button type="button" class="btn btn--trans btn--mini ml16"
                    @click="openPopup(item, popups.letCustomerThink.name)" v-if="false">
                дать подумать
            </button>

            <!-- Новые заявки -->
            <!-- Проверка договора buttons.measureDetail -->
            <a href="javascript:void(0);"
                class="link link-yellow ml16 text-size-xs text-uppercase text-bold can-load"
                @click="openMeasureDetail(item)" v-if="hasState(['request_check_contract_default'])"
                :class="{'load': buttons.measureDetail === 'load'}">
                Проверить данные
            </a>
            <button type="button" class="btn btn--trans btn--mini ml16"
                    @click="openPopup(item, popups.rejectRequestNow.name)"
                    v-if="hasState(['request_new_metering_empty'])">
                это спам
            </button>
            <button @click="openPopup(item, popups.notCall.name)" type="button"
                    class="btn btn--mini btn--trans ml16" v-if="hasState(['request_new_metering_empty'])">
                недозвон
            </button>
            <button type="button" class="btn btn--trans btn--mini ml16"
                    @click="openPopup(item, popups.requestThink.name)"
                    v-if="hasState(['request_new_metering_empty','request_cant_call_default'])">
                дать подумать
            </button>
            <button type="button" class="btn btn--trans btn--mini ml16" @click="openRejectRequest(item)"
                    v-if="hasState([
                    'request_new_reject',
                    'request_new_default',
                    'request_new_metering_empty',
                    'request_wait_contract_default',
                    'request_check_contract',
                    'request_check_contract_default',
                    'request_pre_approved_default',
                    'request_wait_interview_default',
                    'request_think_default',
                    'request_cant_call_default']
        )">Отклонить
            </button>
            <button type="button" class="btn btn--mini ml16" @click="openPopup(item, popups.standardMeasure.name)"
                    v-if="hasState(['request_new_metering_empty','request_think_default','request_cant_call_default'])">
                Обычный замер
            </button>
            <button type="button" class="btn btn--mini ml16" @click="openPopup(item, popups.remoteMeasure.name)"
                    v-if="hasState(['request_new_metering_empty','request_think_default','request_cant_call_default'])">
                Дистанционный замер
            </button>

            <button type="button" class="btn btn--mini ml16" @click="openPopup(item, popups.approve.name)"
                    v-if="hasState([ 'request_rejected_default'])">
                Выставить счёт повторно
            </button>
            <!-- Отложенные замеры -->
            <a href="javascript:void(0);" class="link link-yellow ml16 text-size-xs text-uppercase text-bold"
                @click="openPopup(item, popups.deferReason.name)" v-if="hasState(['measure_put_aside_default'])">
                посмотреть причину
            </a>
            <!-- Отложенные замеры -->
            <a href="javascript:void(0);" class="link link-yellow ml16 text-size-xs text-uppercase text-bold"
                @click="openPopup(item, popups.cancelReason.name)" v-if="hasState(['measure_canceled_default'])">
                посмотреть причину
            </a>

            <!-- Предварительно одобренные -->
            <button type="button" class="btn btn--trans btn--mini ml16"
                    @click="openPopup(item, popups.scheduleInterview.name)"
                    v-if="hasState(['request_check_contract_default','request_pre_approved_default'])">
                Назначить собеседование с руководителем
            </button>

            <button type="button" class="btn btn--mini ml16" @click="openPopup(item, popups.approve.name)"
                    v-if="hasState(['request_check_contract_default','request_pre_approved_default','request_wait_interview_default'])">
                Взять в работу и запросить предоплату
            </button>

            <!-- Ждут cобеседования  request_wait_interview_default -->

            <!--Подтвержденные замеры -->
            <button @click="openRestoreStatus(item, 'measure_queue', 'В очереди')" type="button"
                    class="btn btn--trans btn--mini ml16" v-if="hasState(['measure_confirmed_ARKR'])">
                Вернуть «В очереди»
            </button>

            <submit-btn :is-loader-in="buttons.measureTime === 'load'" :type="buttons.measureTime"
                        @click="openMeasureTime(item)" type="button" class="btn btn--mini ml16"
                        v-if="hasState(['measure_confirmed_Z'])">
                назначить время замера
            </submit-btn>

            <!-- Замеры в очереди -->
            <button @click="openPopup(item, popups.deferMeasure.name)" type="button"
                    class="btn btn--trans btn--mini ml16"
                    v-if="hasState(['measure_queue_default','measure_confirmed_ARKR', 'measure_in_work_ARKR'])">
                Отложить
            </button>
            <button @click="openPopup(item, popups.cancelMeasure.name)" type="button"
                    class="btn btn--trans btn--mini ml16"
                    v-if="hasState(['measure_queue_default','measure_confirmed_ARKR', 'measure_in_work_ARKR', 'measure_in_work_ARKR_bill', 'measure_put_aside_default'])">
                Отменить
            </button>
            <button @click="openPopup(item, popups.confirmMeasure.name)" type="button" class="btn btn--mini ml16"
                    v-if="hasState(['measure_queue_default'])">
                Подтвердить
            </button>

            <!-- Замеры в работе -->
            <button @click="openPopup(item, popups.acceptCash.name)" type="button" class="btn btn--mini ml16"
                    v-if="hasState(['measure_in_work_Z'])">
                Принять наличные
            </button>
            <!-- Замеры в работе -->
            <button @click="openPopup(item, popups.acceptCard.name)" type="button" class="btn btn--mini ml16"
                    v-if="hasState(['measure_in_work_Z'])">
                Принять оплату картой
            </button>
            <button @click="openPopup(item, popups.issueInvoice.name)" type="button" class="btn btn--mini ml16"
                    v-if="hasState(['measure_in_work_Z','measure_in_work_Z_cashless'])">
                Выставить счёт
            </button>
            <button @click="openPopup(item, popups.acceptMeasurerMoney.name)" type="button"
                    class="btn btn--mini ml16" v-if="hasState(['director_measurer_money'])">
                Принять деньги у замерщика
            </button>

            <!-- Отложенные замеры -->
            <submit-btn :is-loader-in="buttons.resumeMeasure === 'load'" :type="buttons.resumeMeasure"
                        @click="openResumeMeasure(item)" type="button" class="btn btn--mini ml16"
                        v-if="hasState(['measure_put_aside_default'])">
                возобновить
            </submit-btn>

            <!-- Концепции в разработке -->
            <button @click="openPopup(item, popups.downloadConcept.name)" type="button" class="btn btn--mini ml16"
                    v-if="hasState(['concepts_in_work_default'])">
                загрузить одобренную концепцию и файл замера
            </button>

            <!-- Одобренные концепции -->
            <button @click="openPopup(item, popups.moveSeen.name)" type="button" class="btn btn--mini btn--trans ml16"
                        v-if="hasState(['concepts_approved_ARKR'])">
                    перевести в «Посмотрели»
            </button>
            
            <button @click="openPopup(item, popups.acceptConcept.name)" type="button" class="btn btn--mini ml16"
                    v-if="hasState(['concepts_approved_ARKR'])">
                принять концепцию у замерщика
            </button>

            <!-- Концепции на согласовании -->

            <button @click="openRestoreStatus(item, 'concepts_watched_video', 'Посмотрели')" type="button"
                    class="btn btn--trans btn--mini ml16" v-if="hasState(['concepts_on_approval_default'])">
                Вернуть в «Посмотрели»
            </button>

            <button @click="openPopup(item, popups.pauseConcept.name)" type="button"
                    class="btn btn--trans btn--mini ml16"
                    v-if="hasState(['concepts_watch_video_default','concepts_watched_video_default','concepts_on_approval_default','concepts_awaiting_default','concepts_accepted_default'])">
                поставить на паузу
            </button>
            <submit-btn @click="openConceptConfirm(item)" :is-loader-in="buttons.conceptConfirm === 'load'"
                        :type="buttons.conceptConfirm" class="btn btn--mini ml16"
                        v-if="hasState(['concepts_on_approval_default'])">
                Запросить подтверждение
            </submit-btn>

            <!-- Принятые концепции -->
            <button @click="openPopup(item, popups.scheduleAgreement.name)" type="button" class="btn btn--mini ml16"
                    v-if="hasState(['concepts_watched_video_default','concepts_accepted_default'])">
                Назначить время согласования
            </button>
            <!-- Ожидают подтверждения -->
            <button @click="openPopup(item, popups.confirmForCustomer.name)" type="button"
                    class="btn btn--mini ml16" v-if="hasState(['concepts_awaiting_default'])">
                подтвердить за заказчика
            </button>

            <!-- 3d модели в разработке -->
            <button @click="openPopup(item, popups.modelRequestReview.name)" type="button"
                    class="btn btn--mini ml16" v-if="hasState(['3d_in_work_default'])">
                сдать на проверку
            </button>

            <!-- Модели на проверке -->
            <button @click="openPopup(item, popups.modelChecked.name)" type="button" class="btn btn--mini ml16"
                    v-if="hasState(['3d_check_P'])">
                отметить проверенным
            </button>
            <!-- Демонстрация модели -->
            <button @click="openRestoreStatus(item, '3d_verified', 'Проверены')" type="button"
                    class="btn btn--trans btn--mini ml16" v-if="hasState(['3d_demo_default'])">
                Вернуть в «Проверены»
            </button>
            <button @click="openPopup(item, popups.modelSetPause.name)" type="button"
                    class="btn btn--trans btn--mini ml16"
                    v-if="hasState(['3d_demo_default','3d_awaiting_default','3d_verified_default'])">
                поставить на паузу
            </button>
            <!-- Проверенные модели -->
            <button @click="openPopup(item, popups.modelDemo.name)" type="button" class="btn btn--mini ml16"
                    v-if="hasState(['3d_verified_default'])">
                назначить время демонстрации
            </button>
            <button @click="openPopup(item, popups.modelRequestConfirm.name)" type="button"
                    class="btn btn--mini ml16" v-if="hasState(['3d_demo_default'])">
                запросить подтверждение
            </button>

            <!-- Ожидают подтверждение -->
            <button @click="openPopup(item, popups.modelConfirmAsCustomer.name)" type="button"
                    class="btn btn--mini ml16" v-if="hasState(['3d_awaiting_default'])">
                подтвердить за заказчика
            </button>

            <!-- Концепции на паузе -->
            <a @click="openPopup(item, popups.pauseReason.name)" href="javascript:void(0);"
                class="link link-yellow ml16 text-size-xs text-uppercase text-bold"
                v-if="hasState(['concepts_paused_default'])">
                посмотреть причину
            </a>
            <button @click="openPopup(item, popups.resumeConcept.name)" type="button" class="btn btn--mini ml16"
                    v-if="hasState(['concepts_paused_default'])">
                вернуть в «Посмотрели видеообращение»
            </button>
            <!-- Модели на паузе -->
            <a @click="openPopup(item, popups.modelPauseReason.name)" href="javascript:void(0);"
                class="link link-yellow ml16 text-size-xs text-uppercase text-bold"
                v-if="hasState(['3d_paused_default'])">
                посмотреть причину
            </a>
            <button @click="openPopup(item, popups.resumeModel.name)" type="button" class="btn btn--mini ml16"
                    v-if="hasState(['3d_paused_default'])">
                ВЕРНУТЬ В «ПРОВЕРЕНЫ»
            </button>
            <!-- Чертежи в разработке -->
            <button @click="openPopup(item, popups.drawForReview.name)" type="button" class="btn btn--mini ml16"
                    v-if="!isNPSMeasure && hasState(['drawing_in_work_T'])">
                сдать на проверку
            </button>
            <!-- 3d модели на проверке -->
            <!-- Чертежи на проверке -->
            <button @click="openPopup(item, popups.markDrawVerify.name)" type="button" class="btn btn--mini ml16"
                    v-if="!isNPSMeasure && hasState(['drawing_check_P'])">
                отметить проверенным
            </button>

            <!-- Проверенные чертежи -->
            <button @click="openPopup(item, popups.markReady.name)" type="button" class="btn btn--mini ml16"
                    v-if="hasState(['drawing_verified_T'])">
                Загрузить готовый проект
            </button>

            <!-- Готовые проекты -->
            <button @click="openPopup(item, popups.makeDecision.name)" type="button" class="btn btn--mini ml16"
                    v-if="!isNPSMeasure && hasState(['drawing_ready_default'])">
                принять решение по ремонту
            </button>

            <!-- Ожидают передачи -->
            <button @click="openRestoreStatus(item, 'drawing_ready', 'Готовы')" type="button"
                    class="btn btn--trans btn--mini ml16"
                    v-if="!isNPSMeasure && hasState(['drawing_pending_wait','drawing_pending_no_date','drawing_pending_no_repair','drawing_pending_default',])">
                Вернуть в «Готовы»
            </button>

            <button @click="openPage(item)" type="button" class="btn btn--trans btn--mini ml16"
                    v-if="hasState(['drawing_pending_director']) && !isNPSMeasure && !isNPSCollect">
                Передать принудительно
            </button>

            <!-- Ремонты -->
            <button @click="openPopup(item, popups.repairThink.name)" type="button"
                    class="btn btn--mini btn--trans ml16" v-if="hasState(['repair_request_M'])">
                дать подумать
            </button>
            <button @click="openPopup(item, popups.refuseRepair.name)" type="button"
                    class="btn btn--mini btn--trans ml16"
                    v-if="hasState(['repair_request_M','repair_think_M','repair_aside_M','repair_prepay_M'])">
                отказ
            </button>
            <button @click="openPopup(item, popups.repairAside.name)" type="button"
                    class="btn btn--mini btn--trans ml16" v-if="hasState(['repair_request_M','repair_think_M'])">
                отложить
            </button>
            <button @click="openPopup(item, popups.repairAsideMore.name)" type="button"
                    class="btn btn--mini btn--trans ml16" v-if="hasState(['repair_aside_M'])">
                Отложить еще
            </button>
            <button @click="openTakePrepay(item)" type="button" class="btn btn--mini ml16"
                    v-if="hasState(['repair_request_M','repair_think_M','repair_aside_M'])">
                предоплата
            </button>
            <button @click="openPopup(item, popups.takeToWork.name)" type="button" class="btn btn--mini ml16"
                    v-if="hasState(['repair_request_M','repair_think_M','repair_aside_M', 'repair_prepay_M'])">
                взять в работу
            </button>

            <button @click="openPopup(item, popups.repairTerminate.name)" type="button"
                    class="btn btn--mini btn--trans ml16" v-if="hasState(['repair_in_process_M'])">
                расторгнуть договор
            </button>
            <button @click="openPopup(item, popups.repairFinish.name)" type="button" class="btn btn--mini ml16"
                    v-if="hasState(['repair_in_process_M'])">
                завершить ремонт
            </button>

            <!-- НПС -->
            <button @click="openPopup(item, popups.npsNotCall.name)" type="button"
                    class="btn btn--mini btn--trans ml16"
                    v-if="isNPSCollect && item.props.nps_status != 'not-call' && user.groupCode == 'N'">
                недозвон
            </button>
            <button @click="openPopup(item, popups.rateOurWork.name)" type="button" class="btn btn--mini ml16"
                    v-if="isNPSCollect && user.groupCode == 'N'">
                поставить оценку
            </button>
            <button @click="openPopup(item, popups.rateOurWork.name)" type="button" class="btn btn--mini ml16"
                    v-if="isNPSMeasure && user.groupCode == 'N'">
                добавить информацию
            </button>

            <button @click="openPopup(item, popups.purposeThink.name)" type="button"
                    class="btn btn--mini btn--trans ml16" v-if="hasState(['purpose_new_S'])">
                дать подумать
            </button>
            <button @click="openPopup(item, popups.purposeRepairNotNeed.name)" type="button"
                    class="btn btn--mini btn--trans ml16" v-if="hasState(['purpose_new_S','purpose_think_S'])">
                ремонт не нужен
            </button>
            <button @click="openPopup(item, popups.purposeSetMaster.name)" type="button" class="btn btn--mini ml16"
                    v-if="hasState(['purpose_new_S','purpose_think_S','purpose_repair_not_need_S'])">
                назначить исполнителя
            </button>
            <!-- Бухгалтер -->
            <div class="ml16" v-if="hasState(['repair_in_process_analytic']) && menuTopItemCode === 'repair'">
                <tooltip
                    :direction="'bottom-right-direction'"
                    :is-btn-close="false"
                    :add-class-wrap="'details-tooltip'"
                    :isChangeablePos="false"
                    :add-style="{width: '264px'}">
                    <template v-slot:tooltipBtn>
                        <a href="javascript:void(0);"
                           class="link ml0 link-yellow text-size-xs text-uppercase text-bold">
                            подробности
                        </a>
                    </template>

                    <template v-slot:tooltipContent>
                        <div v-if="item.extProps.stamp === 'order_submitted'" class="">
                            {{ item.props.acceptReportComment }} <br> <br>
                            {{ item.props.acceptReportUser.lastName }} {{ item.props.acceptReportUser.firstName }}
                            ({{
                                item.props.acceptReportUser.groupCode + (item.props.acceptReportUser.number ? item.props.acceptReportUser.number : '')
                            }}), {{ item.props.statusDate }}
                        </div>
                        <div v-else class="">
                            <span v-html="convertNewLine(item.props.repairComment )"></span> <br> <br>
                            {{ item.props.repairCommentUser.lastName }} {{ item.props.repairCommentUser.firstName }}
                            ({{
                                item.props.repairCommentUser.groupCode + (item.props.repairCommentUser.number ? item.props.repairCommentUser.number : '')
                            }}), {{ item.props.statusDate }}
                        </div>
                    </template>
                </tooltip>
            </div>
            <button @click="openPopup(item, popups.acceptReport.name)" type="button" class="btn btn--mini ml16"
                    v-if="hasState(['repair_in_process_fin','repair_in_process_director']) && item.extProps.stamp === 'order_not_submitted' && (user.groupCode == 'V' || user.groupCode == 'F')">
                принять отчёт
            </button>
        </div>
    </div>

</template>

<script>
import { mapState } from 'vuex';
import Tooltip from '@/components/Tooltip.vue';
import SubmitBtn from '@/components/forms/SubmitBtn.vue';
import Stamp from '@/components/BazaItem/Stamp.vue';
import TopBlock from '@/components/BazaItem/TopBlock.vue';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { convertNewLine } from '@/helpers/util/helper';
import TopItemBlockReward from '@/components/BazaItem/TopItemBlockReward.vue';
import BazaItemHeader from '@/components/BazaItem/Header.vue';
import NpsCollectTooltip from '@/components/BazaItem/Tooltips/Nps/NpsCollect.vue';
import NpsMeasureTooltip from '@/components/BazaItem/Tooltips/Nps/NpsMeasure.vue';
import SmsOutOfTimeLink from '@/components/BazaItem/SmsOutOfTimeLink.vue';
import RepairTerminateTooltip from '@/components/BazaItem/Tooltips/RepairTerminate.vue';
import ConceptsPauseTooltip from '@/components/BazaItem/Tooltips/ConceptsPause.vue';
import D3PauseTooltip from '@/components/BazaItem/Tooltips/D3Pause.vue';
import RepairPrepayTooltip from '@/components/BazaItem/Tooltips/RepairPrepay.vue';
import RepairCommentTooltip from '@/components/BazaItem/Tooltips/RepairComment.vue';
import RequestThinkTooltip from '@/components/BazaItem/Tooltips/RequestThink.vue';
import DrawingTransferredTooltip from '@/components/BazaItem/Tooltips/DrawingTransferred.vue';
import RequestRejectTooltip from '@/components/BazaItem/Tooltips/RequestReject.vue';
import RequestNewTooltip from '@/components/BazaItem/Tooltips/RequestNew.vue';
import RepairNotNeedTooltip from '@/components/BazaItem/Tooltips/RepairNotNeed.vue';
import PurposeNewTooltip from '@/components/BazaItem/Tooltips/PurposeNew.vue';
import PurposeNotNeedTooltip from '@/components/BazaItem/Tooltips/PurposeNotNeed.vue';
import PurposeThinkTooltip from '@/components/BazaItem/Tooltips/PurposeThink.vue';
import PurposeSetTooltip from '@/components/BazaItem/Tooltips/PurposeSet.vue';

export default {
    name: 'BazaItemHeader',
    components: {
        SmsOutOfTimeLink,
        Tooltip,
        SubmitBtn,
        Splide,
        SplideSlide,
        Stamp,
        TopBlock,
        TopItemBlockReward,
        BazaItemHeader,
        NpsCollectTooltip,
        NpsMeasureTooltip,
        D3PauseTooltip,
        ConceptsPauseTooltip,
        RepairTerminateTooltip,
        RepairPrepayTooltip,
        RepairCommentTooltip,
        RequestThinkTooltip,
        DrawingTransferredTooltip,
        RequestRejectTooltip,
        RequestNewTooltip,
        RepairNotNeedTooltip,
        PurposeNewTooltip,
        PurposeNotNeedTooltip,
        PurposeThinkTooltip,
        PurposeSetTooltip,
    },
    props: {
        item: {},
    },
    data() {
        return {
            buttons: {
                measureDetail: 'normal',
                conceptConfirm: 'normal',
                measureTime: 'normal',
                resumeMeasure: 'normal',
                log: 'normal',
                sendSms: 'normal',
            },
            showConceptGallery: false,
            loadContactGallery: false,
        };
    },
    computed: {
        ...mapState({
            popups: state => state.popups.items,
            user: state => state.auth.user,
            workers: state => state.requests.workers,
            menuList: state => state.menus.itemsTop,
        }),
        menuTopItemCode() {
            let code = '';

            if (this.$route) {
                code = this.$route.path;
            }

            if (typeof code !== 'undefined') {
                let arPath = code.split('/');
                let isSuccess = false;
                for (let i in this.menuList) {
                    if (arPath[1] == this.menuList[i].code) {
                        isSuccess = true;
                    }
                }
                if (isSuccess) {
                    code = arPath[1];
                } else {
                    code = false;
                }
            }

            if (!code && Object.values(this.menuList)[0]) {
                code = Object.values(this.menuList)[0].code;
            }

            return code;
        },
        section() {
            return this.$route.name;
        },
        clientName() {
            let name = this.item.props.clientSurname + ' ' + this.item.props.clientName + ' ' + this.item.props.clientPatronymic;
            return name.trim();
        },
        requestStatusTimePassed() {
            return this.item.props.passedTimeDesc;
        },
        requestStatusDays() {
            return this.item.props.daysFromStatusDate;
        },
        statesForDirectorMarks() {
            return [
                'concepts_in_work_director',
                'concepts_approved_director',
                'concepts_accepted_director',
                'concepts_on_approval_director',
                'concepts_awaiting_director',
                'concepts_paused_director',
                '3d_in_work_director',
                '3d_check_director',
                '3d_verified_director',
                '3d_demo_director',
                '3d_awaiting_director',
                '3d_paused_director',
                'drawing_in_work_director',
                'drawing_check_director',
                'drawing_verified_director',
                'drawing_ready_director',
                'drawing_transferred_director',
                'drawing_pending_director',
                'concepts_in_work_TD',
                'concepts_approved_TD',
                'concepts_accepted_TD',
                'concepts_on_approval_TD',
                'concepts_awaiting_TD',
                'concepts_paused_TD',
                '3d_in_work_TD',
                '3d_check_TD',
                '3d_verified_TD',
                '3d_demo_TD',
                '3d_awaiting_TD',
                '3d_paused_TD',
                'drawing_in_work_TD',
                'drawing_check_TD',
                'drawing_verified_TD',
                'drawing_ready_TD',
                'drawing_transferred_TD',
                'drawing_pending_TD',
            ];
        },
        isNPSCollect() {
            return (this.$route.name === 'nps-collect' || this.$route.name === 'nps-collect-current');
        },
        isNPSMeasure() {
            return this.$route.name === 'nps-measure' || this.$route.name === 'nps-measure-current';
        },
        isGiveDesign() {
            return this.$route.name === 'give-design';
        },
    },
    methods: {
        convertNewLine(text) {
            return convertNewLine(text);
        },
        hasState(list) {
            let state = this.item.extProps.state;
            for (let i in list) {
                if (list[i].indexOf('*') + 1) {
                    let split = this.item.extProps.state.split('_');
                    split[split.length - 1] = '*';

                    if (list[i] === split.join('_')) {
                        state = split.join('_');
                    }
                }
            }

            return list.indexOf(state) !== -1;
        },
        openPopup(item, popup) {
            if ((popup == this.popups.repairTerminate.name || popup == this.popups.repairFinish.name) && item.extProps.stamp == 'order_not_submitted') {
                this.$store.dispatch('popups/open', { name: this.popups.repairFinishError.name, request: item });
            } else {
                this.$store.dispatch('popups/open', { name: popup, request: item });
            }

        },

        openPage(item) {
            window.open(item.props.autosale_link);
        },
        openFillSpecific(item, back) {
            this.$store.commit(`configurator/setCurrentItem`, item);
            this.$router.push(`/fill-specific/${item.id}${back}`);
        },
        openCheckSpecific(item, back) {
            this.$store.commit(`configurator/setCurrentItem`, item);
            this.$router.push(`/check-specific/${item.id}${back}`);
        },
        openTakePrepay(item) {
            this.buttons.sendSms = 'load';
            this.$store.dispatch('popups/getData', { id: item.id, modalData: 'TakePrepay' }).then(response => {
                this.buttons.sendSms = 'normal';
                this.$store.commit('requests/setItemProps', {
                    item: this.item, props: {
                        months: response.items,
                    },
                });
                this.$nextTick(() => {
                    this.$store.dispatch('popups/open', { name: this.popups.takePrepay.name, request: item });
                });
            });
        },
        openRejectRequest(item) {
            this.buttons.sendSms = 'load';
            this.$store.dispatch('popups/getData', { id: item.id, modalData: 'RejectRequest' }).then(response => {
                this.buttons.sendSms = 'normal';
                this.$store.commit('requests/setItemProps', {
                    item: this.item, props: {
                        reasons: response.items.reasons,
                    },
                });
                this.$nextTick(() => {
                    this.$store.dispatch('popups/open', { name: this.popups.rejectRequest.name, request: item });
                });
            });
        },
        openRestoreStatus(item, code, title) {

            this.$store.commit('requests/setItemProps', {
                item: this.item, props: {
                    restoreStatusCode: code,
                    restoreStatusTitle: title,
                },
            });

            this.$store.dispatch('popups/open', { name: this.popups.restoreStatus.name, request: item });

        },
        openMeasureDetail(item) {
            if (item.contract) {
                this.$store.dispatch('popups/open', { name: this.popups.measureDetail.name, request: item });
            } else {
                this.buttons.measureDetail = 'load';
                this.$store.dispatch('popups/getData', {
                    id: item.props.contractId,
                    modalData: 'MeasureDetail',
                }).then(response => {
                    this.buttons.measureDetail = 'normal';
                    this.$store.commit('requests/setItemProps', {
                        item: this.item, props: {
                            contract: response.items,
                        },
                    });
                    this.$nextTick(() => {
                        this.$store.dispatch('popups/open', { name: 'measureDetail', request: item });
                    });
                });

            }
        },
        openConceptConfirm(item) {
            this.buttons.conceptConfirm = 'load';
            this.$store.dispatch('popups/getData', { id: item.extProps.team.id, modalData: 'Workers' }).then(response => {
                this.buttons.conceptConfirm = 'normal';
                this.$store.commit('requests/setWorkers', response.items);
                this.$store.dispatch('popups/open', { name: this.popups.requestConceptConfirm.name, request: item });
            });
        },
        openMeasureTime(item) {
            let itemSet = { ...item };
            this.buttons.measureTime = 'load';
            this.$store.dispatch('popups/getData', { id: this.item.id, modalData: 'CalendarDays' }).then(response => {
                this.buttons.measureTime = 'normal';
                itemSet.dates = response.items.days;
                this.$store.dispatch('popups/open', { name: this.popups.measureTime.name, request: itemSet });
            });
        },
        openResumeMeasure(item) {
            let itemSet = { ...item };
            this.buttons.resumeMeasure = 'load';
            this.$store.dispatch('popups/getData', { id: this.item.id, modalData: 'CalendarDays' }).then(response => {
                this.buttons.resumeMeasure = 'normal';
                itemSet.dates = response.items.days;
                itemSet.closest = response.items.closest;
                this.$store.dispatch('popups/open', { name: this.popups.resumeMeasure.name, request: itemSet });
            });
        },
    },
};
</script>
